@font-face {
  font-family: "fontello";
  src: url("../font/fontello.ttf?68281809") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?68281809#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-2d_view:before {
  content: "\e800";
} /* '' */
.icon-2d:before {
  content: "\e801";
} /* '' */
.icon-3d_view:before {
  content: "\e802";
} /* '' */
.icon-3d:before {
  content: "\e803";
} /* '' */
.icon-aerialway_chair_lift:before {
  content: "\e804";
} /* '' */
.icon-aerialway_gondola:before {
  content: "\e805";
} /* '' */
.icon-aerialway_lift_go_down:before {
  content: "\e806";
} /* '' */
.icon-aerialway_lift_go_up:before {
  content: "\e807";
} /* '' */
.icon-aerialway_station:before {
  content: "\e808";
} /* '' */
.icon-aerialway:before {
  content: "\e809";
} /* '' */
.icon-alert:before {
  content: "\e80a";
} /* '' */
.icon-alpine_hut_sponsored:before {
  content: "\e80b";
} /* '' */
.icon-alpine_hut:before {
  content: "\e80c";
} /* '' */
.icon-alpine_skiing:before {
  content: "\e80d";
} /* '' */
.icon-alpineskiing_gear:before {
  content: "\e80e";
} /* '' */
.icon-animals_no:before {
  content: "\e80f";
} /* '' */
.icon-animals_yes:before {
  content: "\e810";
} /* '' */
.icon-artificial_card:before {
  content: "\e811";
} /* '' */
.icon-atm:before {
  content: "\e812";
} /* '' */
.icon-attention:before {
  content: "\e813";
} /* '' */
.icon-baby_backpack:before {
  content: "\e814";
} /* '' */
.icon-baby_trail:before {
  content: "\e815";
} /* '' */
.icon-bicycle-_rent:before {
  content: "\e816";
} /* '' */
.icon-bicycle_bus_stop:before {
  content: "\e817";
} /* '' */
.icon-bicycle_facilities:before {
  content: "\e818";
} /* '' */
.icon-bicycle_info:before {
  content: "\e819";
} /* '' */
.icon-bicycle_rent:before {
  content: "\e81a";
} /* '' */
.icon-bicycle_repair_sponsored:before {
  content: "\e81b";
} /* '' */
.icon-bicycle_repair:before {
  content: "\e81c";
} /* '' */
.icon-bicycle_sell:before {
  content: "\e81d";
} /* '' */
.icon-bicycle_wash:before {
  content: "\e81e";
} /* '' */
.icon-bike_shops:before {
  content: "\e81f";
} /* '' */
.icon-bus_stop:before {
  content: "\e820";
} /* '' */
.icon-bus:before {
  content: "\e821";
} /* '' */
.icon-cableway_trail:before {
  content: "\e822";
} /* '' */
.icon-cam_ar:before {
  content: "\e823";
} /* '' */
.icon-charging_station:before {
  content: "\e824";
} /* '' */
.icon-check_encircled_thin:before {
  content: "\e825";
} /* '' */
.icon-check_medium:before {
  content: "\e826";
} /* '' */
.icon-check_thin:before {
  content: "\e827";
} /* '' */
.icon-chevron_down:before {
  content: "\e828";
} /* '' */
.icon-chevron_left:before {
  content: "\e829";
} /* '' */
.icon-chevron_right:before {
  content: "\e82a";
} /* '' */
.icon-chevron_up:before {
  content: "\e82b";
} /* '' */
.icon-circle_trail:before {
  content: "\e82c";
} /* '' */
.icon-close_encircled_thin:before {
  content: "\e82d";
} /* '' */
.icon-close_thin:before {
  content: "\e82e";
} /* '' */
.icon-close:before {
  content: "\e82f";
} /* '' */
.icon-compass:before {
  content: "\e830";
} /* '' */
.icon-crampons:before {
  content: "\e831";
} /* '' */
.icon-crosscountry_skiing_gear:before {
  content: "\e832";
} /* '' */
.icon-crosscountry_skiing:before {
  content: "\e833";
} /* '' */
.icon-difficulty_trail:before {
  content: "\e834";
} /* '' */
.icon-disabled_instructor:before {
  content: "\e835";
} /* '' */
.icon-discover:before {
  content: "\e836";
} /* '' */
.icon-doctor:before {
  content: "\e837";
} /* '' */
.icon-down:before {
  content: "\e838";
} /* '' */
.icon-downhill_trail:before {
  content: "\e839";
} /* '' */
.icon-duration_trail:before {
  content: "\e83a";
} /* '' */
.icon-eat_and_sleep:before {
  content: "\e83b";
} /* '' */
.icon-eat_sleep:before {
  content: "\e83c";
} /* '' */
.icon-elevation_profile_flat:before {
  content: "\e83d";
} /* '' */
.icon-elevation_profile_outline:before {
  content: "\e83e";
} /* '' */
.icon-emotion:before {
  content: "\e83f";
} /* '' */
.icon-exp_carriage:before {
  content: "\e840";
} /* '' */
.icon-exp_fat_bike:before {
  content: "\e841";
} /* '' */
.icon-exp_freeride:before {
  content: "\e842";
} /* '' */
.icon-exp_ice_climbing:before {
  content: "\e843";
} /* '' */
.icon-exp_paragliding:before {
  content: "\e844";
} /* '' */
.icon-exp_snow_shoes:before {
  content: "\e845";
} /* '' */
.icon-exp_snowcat:before {
  content: "\e846";
} /* '' */
.icon-exp_snowscoot:before {
  content: "\e847";
} /* '' */
.icon-exp_strider_bike:before {
  content: "\e848";
} /* '' */
.icon-exp_trekking:before {
  content: "\e849";
} /* '' */
.icon-family_trail:before {
  content: "\e84a";
} /* '' */
.icon-filtri_menu_tab:before {
  content: "\e84b";
} /* '' */
.icon-finish_trail:before {
  content: "\e84c";
} /* '' */
.icon-first_steps:before {
  content: "\e84d";
} /* '' */
.icon-food:before {
  content: "\e84e";
} /* '' */
.icon-fountain:before {
  content: "\e84f";
} /* '' */
.icon-friends:before {
  content: "\e850";
} /* '' */
.icon-go:before {
  content: "\e851";
} /* '' */
.icon-googles_and_mask:before {
  content: "\e852";
} /* '' */
.icon-grid_view:before {
  content: "\e853";
} /* '' */
.icon-group_outing_2:before {
  content: "\e854";
} /* '' */
.icon-group_outing_fullcolor:before {
  content: "\e855";
} /* '' */
.icon-group_outing_outline:before {
  content: "\e856";
} /* '' */
.icon-group_outing:before {
  content: "\e857";
} /* '' */
.icon-gym:before {
  content: "\e858";
} /* '' */
.icon-handicap_yes:before {
  content: "\e859";
} /* '' */
.icon-helmet:before {
  content: "\e85a";
} /* '' */
.icon-historical_point:before {
  content: "\e85b";
} /* '' */
.icon-horizontal_view:before {
  content: "\e85c";
} /* '' */
.icon-ice_skating:before {
  content: "\e85d";
} /* '' */
.icon-info_point_outdoor:before {
  content: "\e85e";
} /* '' */
.icon-info_point_panel_ar:before {
  content: "\e85f";
} /* '' */
.icon-info_point_panel:before {
  content: "\e860";
} /* '' */
.icon-info_point_sponsored:before {
  content: "\e861";
} /* '' */
.icon-info_point:before {
  content: "\e862";
} /* '' */
.icon-instructor_meeting_point:before {
  content: "\e863";
} /* '' */
.icon-kart:before {
  content: "\e864";
} /* '' */
.icon-kids_trail:before {
  content: "\e865";
} /* '' */
.icon-kinderclub:before {
  content: "\e866";
} /* '' */
.icon-landscape_trail:before {
  content: "\e867";
} /* '' */
.icon-left:before {
  content: "\e868";
} /* '' */
.icon-legend:before {
  content: "\e869";
} /* '' */
.icon-lenght_card:before {
  content: "\e86a";
} /* '' */
.icon-length:before {
  content: "\e86b";
} /* '' */
.icon-like:before {
  content: "\e86c";
} /* '' */
.icon-live_area:before {
  content: "\e86d";
} /* '' */
.icon-location_trail:before {
  content: "\e86e";
} /* '' */
.icon-locator:before {
  content: "\e86f";
} /* '' */
.icon-login_community:before {
  content: "\e870";
} /* '' */
.icon-login_live_track:before {
  content: "\e871";
} /* '' */
.icon-login:before {
  content: "\e872";
} /* '' */
.icon-logout:before {
  content: "\e873";
} /* '' */
.icon-low:before {
  content: "\e874";
} /* '' */
.icon-max_slope:before {
  content: "\e875";
} /* '' */
.icon-menu:before {
  content: "\e876";
} /* '' */
.icon-modify:before {
  content: "\e877";
} /* '' */
.icon-more_horizontal:before {
  content: "\e878";
} /* '' */
.icon-more_vertical:before {
  content: "\e879";
} /* '' */
.icon-mowi:before {
  content: "\e87a";
} /* '' */
.icon-my_hotel:before {
  content: "\e87b";
} /* '' */
.icon-natural_card:before {
  content: "\e87c";
} /* '' */
.icon-new_friend:before {
  content: "\e87d";
} /* '' */
.icon-nhd_card:before {
  content: "\e87e";
} /* '' */
.icon-outdoor_school_sponsored:before {
  content: "\e87f";
} /* '' */
.icon-outdoor_school:before {
  content: "\e880";
} /* '' */
.icon-panorama:before {
  content: "\e881";
} /* '' */
.icon-parking:before {
  content: "\e882";
} /* '' */
.icon-party:before {
  content: "\e883";
} /* '' */
.icon-patrol_alert:before {
  content: "\e884";
} /* '' */
.icon-patrol_archive:before {
  content: "\e885";
} /* '' */
.icon-patrol_management_section_standby:before {
  content: "\e886";
} /* '' */
.icon-patrol_management_section_trail_check:before {
  content: "\e887";
} /* '' */
.icon-patrol_management_warning:before {
  content: "\e888";
} /* '' */
.icon-patrol_symbol:before {
  content: "\e889";
} /* '' */
.icon-patrol_top_bar_tacking_on:before {
  content: "\e88a";
} /* '' */
.icon-patrol_top_bar_trail_status:before {
  content: "\e88b";
} /* '' */
.icon-patrol_top_bar_warning_list:before {
  content: "\e88c";
} /* '' */
.icon-pedestrians_no:before {
  content: "\e88d";
} /* '' */
.icon-pedestrians_yes:before {
  content: "\e88e";
} /* '' */
.icon-period_trail:before {
  content: "\e88f";
} /* '' */
.icon-pharmacy:before {
  content: "\e890";
} /* '' */
.icon-phd_card:before {
  content: "\e891";
} /* '' */
.icon-plus_encircled_thin:before {
  content: "\e892";
} /* '' */
.icon-plus_thin:before {
  content: "\e893";
} /* '' */
.icon-point_danger:before {
  content: "\e894";
} /* '' */
.icon-point_forbidden:before {
  content: "\e895";
} /* '' */
.icon-point_warning:before {
  content: "\e896";
} /* '' */
.icon-profile:before {
  content: "\e897";
} /* '' */
.icon-pub_sponsored:before {
  content: "\e898";
} /* '' */
.icon-pub:before {
  content: "\e899";
} /* '' */
.icon-public_utility:before {
  content: "\e89a";
} /* '' */
.icon-record_activities:before {
  content: "\e89b";
} /* '' */
.icon-rent_sponsored:before {
  content: "\e89c";
} /* '' */
.icon-rent:before {
  content: "\e89d";
} /* '' */
.icon-repair_kit:before {
  content: "\e89e";
} /* '' */
.icon-report_abuse:before {
  content: "\e89f";
} /* '' */
.icon-restaurant:before {
  content: "\e8a0";
} /* '' */
.icon-review_comment:before {
  content: "\e8a1";
} /* '' */
.icon-review_like:before {
  content: "\e8a2";
} /* '' */
.icon-review_profile:before {
  content: "\e8a3";
} /* '' */
.icon-riding_school:before {
  content: "\e8a4";
} /* '' */
.icon-right:before {
  content: "\e8a5";
} /* '' */
.icon-school_sponsored:before {
  content: "\e8a6";
} /* '' */
.icon-school:before {
  content: "\e8a7";
} /* '' */
.icon-schoolcamp:before {
  content: "\e8a8";
} /* '' */
.icon-search_in_map_2:before {
  content: "\e8a9";
} /* '' */
.icon-search_in_map:before {
  content: "\e8aa";
} /* '' */
.icon-search_trail:before {
  content: "\e8ab";
} /* '' */
.icon-search:before {
  content: "\e8ac";
} /* '' */
.icon-sell:before {
  content: "\e8ad";
} /* '' */
.icon-settings:before {
  content: "\e8ae";
} /* '' */
.icon-shop_bicycle:before {
  content: "\e8af";
} /* '' */
.icon-shop_sponsored:before {
  content: "\e8b0";
} /* '' */
.icon-shop_ticket:before {
  content: "\e8b1";
} /* '' */
.icon-shop:before {
  content: "\e8b2";
} /* '' */
.icon-shower:before {
  content: "\e8b3";
} /* '' */
.icon-ski_boots:before {
  content: "\e8b4";
} /* '' */
.icon-ski_bus_stop:before {
  content: "\e8b5";
} /* '' */
.icon-ski_info:before {
  content: "\e8b6";
} /* '' */
.icon-ski_instructor:before {
  content: "\e8b7";
} /* '' */
.icon-ski_school:before {
  content: "\e8b8";
} /* '' */
.icon-ski_sell:before {
  content: "\e8b9";
} /* '' */
.icon-ski_shops:before {
  content: "\e8ba";
} /* '' */
.icon-ski_storage:before {
  content: "\e8bb";
} /* '' */
.icon-skill_area:before {
  content: "\e8bc";
} /* '' */
.icon-skimo_gear:before {
  content: "\e8bd";
} /* '' */
.icon-skimo:before {
  content: "\e8be";
} /* '' */
.icon-sleeding_gear:before {
  content: "\e8bf";
} /* '' */
.icon-sleeding:before {
  content: "\e8c0";
} /* '' */
.icon-sleep:before {
  content: "\e8c1";
} /* '' */
.icon-snow_clothing:before {
  content: "\e8c2";
} /* '' */
.icon-snow_park:before {
  content: "\e8c3";
} /* '' */
.icon-snow_shoes_gear:before {
  content: "\e8c4";
} /* '' */
.icon-snow_shoes:before {
  content: "\e8c5";
} /* '' */
.icon-snow_tubing:before {
  content: "\e8c6";
} /* '' */
.icon-snowboard_gear:before {
  content: "\e8c7";
} /* '' */
.icon-snowboard_instructor:before {
  content: "\e8c8";
} /* '' */
.icon-snowboard:before {
  content: "\e8c9";
} /* '' */
.icon-snowscoot_gear:before {
  content: "\e8ca";
} /* '' */
.icon-snowscoot:before {
  content: "\e8cb";
} /* '' */
.icon-spa:before {
  content: "\e8cc";
} /* '' */
.icon-standard:before {
  content: "\e8cd";
} /* '' */
.icon-start:before {
  content: "\e8ce";
} /* '' */
.icon-strong:before {
  content: "\e8cf";
} /* '' */
.icon-swimming_pool:before {
  content: "\e8d0";
} /* '' */
.icon-technical_difficulty:before {
  content: "\e8d1";
} /* '' */
.icon-th_card:before {
  content: "\e8d2";
} /* '' */
.icon-toilette:before {
  content: "\e8d3";
} /* '' */
.icon-top_trail:before {
  content: "\e8d4";
} /* '' */
.icon-tourist_attraction:before {
  content: "\e8d5";
} /* '' */
.icon-track_center:before {
  content: "\e8d6";
} /* '' */
.icon-track_pump:before {
  content: "\e8d7";
} /* '' */
.icon-trails_alert:before {
  content: "\e8d8";
} /* '' */
.icon-train_stop:before {
  content: "\e8d9";
} /* '' */
.icon-train:before {
  content: "\e8da";
} /* '' */
.icon-transfer:before {
  content: "\e8db";
} /* '' */
.icon-trash:before {
  content: "\e8dc";
} /* '' */
.icon-trekking:before {
  content: "\e8dd";
} /* '' */
.icon-tutorial_bike_park:before {
  content: "\e8de";
} /* '' */
.icon-tutorial_covid:before {
  content: "\e8df";
} /* '' */
.icon-tutorial_cross_country:before {
  content: "\e8e0";
} /* '' */
.icon-tutorial_family_snow:before {
  content: "\e8e1";
} /* '' */
.icon-tutorial_family:before {
  content: "\e8e2";
} /* '' */
.icon-tutorial_gesture_nav:before {
  content: "\e8e3";
} /* '' */
.icon-tutorial_gesture_rotation:before {
  content: "\e8e4";
} /* '' */
.icon-tutorial_gesture_zoom:before {
  content: "\e8e5";
} /* '' */
.icon-tutorial_nature:before {
  content: "\e8e6";
} /* '' */
.icon-tutorial_outdoor:before {
  content: "\e8e7";
} /* '' */
.icon-tutorial_respect:before {
  content: "\e8e8";
} /* '' */
.icon-tutorial_ski:before {
  content: "\e8e9";
} /* '' */
.icon-unlike:before {
  content: "\e8ea";
} /* '' */
.icon-up:before {
  content: "\e8eb";
} /* '' */
.icon-uphill_trail:before {
  content: "\e8ec";
} /* '' */
.icon-vertical_view:before {
  content: "\e8ed";
} /* '' */
.icon-weather:before {
  content: "\e8ee";
} /* '' */
